import React, {Fragment, useContext, useEffect} from 'react';
import { RentalContext } from '../../context'
import { starsStatsForReviews } from '../../containers/Reviews/Reviews';
import { Helmet } from 'react-helmet-async';
const app_domain = process.env.REACT_APP_DOMAIN;

export default function SEO({
    title, description, site_name="minhaautocaravana.com", 
    domain=app_domain,
    url="",
    image="logo_minhaautocaravana.jpg",
    pre_load_images=null
    }) {

    const context = useContext(RentalContext);
    const { reviews, loadReviews } = context;
    useEffect(() => {
        loadReviews();
    });

    const {count, avg, worst, best} = starsStatsForReviews(reviews);
    let json_ld = (
        <script type="application/ld+json">
            {`{
                "@context": "https://schema.org",
                "@type": "LocalBusiness",
                "name": "Minhaautocaravana",
                "description": "` + description + `",
                "image": "https://www.minhaautocaravana.com/logo.jpg",
                "address": {
                "@type": "PostalAddress",
                "streetAddress": "Estrada Nacional 109 número 232",
                "addressLocality": "Calvão",
                "postalCode": "3840-061",
                "addressCountry": "PT"
                },
                "geo": {
                "@type": "GeoCoordinates",
                "latitude": "40.5275",
                "longitude": "-8.7361"
                },
                "telephone": "+351961374649",
                "email": "info@minhaautocaravana.com",
                "url": "https://www.minhaautocaravana.com",
                "openingHoursSpecification": [
                {
                    "@type": "OpeningHoursSpecification",
                    "dayOfWeek": [
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday"
                    ]
                }
                ],
                "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": ` + avg +`,
                "bestRating": ` + best +`,
                "worstRating": ` + worst +`,
                "ratingCount": ` + count +`
                },
                "sameAs": [
                "https://facebook.com/WohnmobilPortugal",
                "https://www.instagram.com/minhaautocaravana"
                ]
            }`}
        </script>)

    let pre_loaded_images = (images) => {
        if (!images) return null;
        return (
            images.map((image, index) => 
                <link key={index} rel="preload" fetchpriority="high" as="image" href={image} type="image/webp"></link>
            )
        );
    }

    return (
        <Helmet prioritizeSeoTags>
            <title>{title}</title>
            <meta name='description' content={description} />
            <link rel="canonical" href={'https://' + domain + '/' + url} />
            <meta property="og:site_name" content={site_name} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:url" content={'https://' + domain + '/' + url} />
            <meta property="og:image" content={'https://' + domain + '/' + image} />
            <meta property="twitter:card" content="summary" />
            <meta property="twitter:domain" content={'https://' + domain} />
            <meta property="twitter:title" content={title} />
            <meta property="twitter:description" content={description} />
            <meta property="og:locale:alternate" content="de_DE" />
            <meta property="og:locale:alternate" content="en_EN" />
            <meta property="og:locale:alternate" content="fr_FR" />
            <meta property="og:locale:alternate" content="pt_PT" />
            {(!url) ? json_ld : null}
            {pre_loaded_images(pre_load_images)}
        </Helmet>
    )
}