import React, { Fragment, useState, useEffect } from 'react';
import { Link, useLocation, Routes, Route, useSearchParams } from 'react-router-dom';
import { Provider as RentalContextProvider } from './context';
import './App.css';
import { withTranslation, useTranslation } from 'react-i18next';
import SEO from './components/SEO/seo';
// import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Webpage from './containers/Webpage/Webpage';

import moment from 'moment';

import ReactGA from 'react-ga';
import logo_de from './img/german.webp';
import logo_pt from './img/portuguese.webp';
import logo_en from './img/english.webp';
import logo_fr from './img/french.webp';
// import logo_ru from './img/russian.jpg';
import locationImage from './img/location.webp';
import youtubeImage from './img/youthumb.webp';
import backgroundImage from './img/header_background_7-512.webp';
import {StarSymbols} from './components/Reviews/ReviewStars';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faHome, faCheck, faTruck, faBed, faBan } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt, faUser } from '@fortawesome/free-regular-svg-icons';

import {getCookieConsentValue} from 'react-cookie-consent';

library.add(
  faHome,
  faCalendarAlt,
  faCheck,
  faTruck,
  faUser,
  faBed,
  faBan
);

['de', 'pt', 'en-gb', 'fr'].forEach((locale) => {
    moment.locale(locale);
});

function initializeReactGA(lang) {
  if (process.env.NODE_ENV || process.env.NODE_ENV !== 'development') {
    if (getCookieConsentValue()) {
      ReactGA.initialize('UA-135432502-1');
      ReactGA.pageview('/'+lang);
    }
  }
}

function ScrollToTopButton(props) {
  const [position, setPosition] = useState(false);

  useEffect(() => {
    const handleVisibility = () => {
      window.pageYOffset > 20 ? setPosition(true) : setPosition(false)
    } 
    window.addEventListener("scroll", handleVisibility)
    // return window.removeEventListener("scroll", handleVisibility)
  });
  
  const scrollToTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'})
  };

  if (position) {
    return ( 
      <button title='Top' className='btn btn-secondary lifted scroll'
                    onClick={ () => { scrollToTop(); }}><div className="arrow up"></div>
      </button>
    );
  }
  
  return null;
}

export function ChangeLanguageButton(props) {
    const {i18n, t} = useTranslation();
    const languages = ['pt', 'de', 'en', 'fr'];//, 'ru'];
    return (
      <div className={(props.second) ? 'chgLngSnd' : 'chgLng'}>
        {languages.map((lng) => {
          // only return lang selection for non-active langs
          if (lng === i18n.language || !languages.includes(lng))
            return null;

          let logo = null;
          switch (lng) {
            case 'pt':
              logo = logo_pt;
              break;
            case 'de':
                logo = logo_de;
                break;
            case 'en':
                logo = logo_en;
                break;
            case 'fr':
                logo = logo_fr;
                break;
            // case 'ru':
            //     logo = logo_ru;
            //     break;
            default:
                break;
          }
          return <Link key={lng} className='float px-1' to={'/?lng=' + lng}><img width="27px" height="16px" src={logo} alt={t('common:changeLanguage')} /></Link>;
        })}
      </div>
    );
}

function useScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}

function App() {
  const { t, i18n } = useTranslation();

  const [searchParams] = useSearchParams();
  const new_lng = searchParams.get("lng")
  if (new_lng && i18n.language!==new_lng)
    i18n.changeLanguage(new_lng);
  const lng = i18n.language

  moment.locale(lng);
  document.documentElement.lang = lng;
  initializeReactGA(lng);
  
  useScrollToTop();
  return (
    <Fragment>
      <RentalContextProvider>
        <SEO 
          title={t('common:title')}
          description={t('common:description')}
          pre_load_images={[backgroundImage, locationImage, youtubeImage]}
        />
        <div className="App">
          <StarSymbols />
          <ScrollToTopButton />
          <Routes>
            <Route path="*" element={<Webpage t={t} i18n={i18n} />} />
          </Routes>
        </div>
        </RentalContextProvider>
    </Fragment>
  );
}

export default withTranslation('common')(App);
