import React, {Fragment, useContext, useEffect } from 'react';
import { RentalContext } from '../../context';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import './Home.css';
import "lazyframe/dist/lazyframe.css";
import MyDateRangePicker from '../../components/MyDateRangePicker/MyDateRangePicker';
import Jumbotron from './Jumbotron';
import { Card, CardBody } from 'reactstrap';
import {ChangeLanguageButton} from '../../App';
import {ReviewStars} from '../../components/Reviews/ReviewStars'
import {averageStarsForReviews} from '../../containers/Reviews/Reviews'
import locationImage from '../../img/location.webp'
import youtubeImage from '../../img/youthumb.webp'
import lazyframe from "lazyframe"

function SearchForm(props) {
  const {t} = props;
  
  return (
    <Fragment>
      <MyDateRangePicker
          startDatePlaceholderText={t('common:pickup')}
          endDatePlaceholderText={t('common:return')}
          ignoreInvalidDates={true}
          linkTo={'/vehicles'}
      />
      <div className="row">
        <div className="col-12 px-3 pt-2 pb-0">
          <label className="sf-btn-label">
            <Link
              role="button"
              className="btn btn-lg btn-primary sf-btn"
              to="/vehicles">
              {t('common:Request')}
            </Link>
          </label>
        </div>
      </div>
    </Fragment>
  );
}

function GalleryItem(props) {
  const {title, src, img} = props;
  lazyframe(".lazyframe")
  return (
    <Card className="mb-4 box-shadow">
      <div 
        className="lazyframe" 
        data-vendor="youtube_nocookie" 
        data-src={src}
        data-title={title}
        data-thumbnail={img}
        data-autoplay={false}
      />
    </Card>
  );  
}

function Gallery(props) {
  const {t} = props;

  return (
    <Fragment>
      <div className="col-9">
        <GalleryItem title={t('title of youtube-video-3')} src={t('link of youtube-video-3')} img={youtubeImage} />
      </div>
    </Fragment>
  );
}

function Home(props){
  const {t, tReady, i18n} = props;

  const context = useContext(RentalContext);
  const { reviews, loadReviews, specials, loadSpecials, specialsLoading } = context;

  useEffect(() => {
    loadReviews();
    loadSpecials();
  });
  
  if (!tReady || specialsLoading ) return <p>{t('common:loading page')}</p>;
  
  const avgStars = averageStarsForReviews(reviews);
  const numReviews = reviews ? reviews.length : 0;
  
  const SpecialsButton = (props) => {
    const specials = props.specials;
    if (specials && specials.length > 0) {
      return (
        <div className="row justify-content-center">
            <div className="col-12 px-3 pt-2 pb-0">
              <label className="sf-btn-label">
                <Link
                  role="button"
                  className="btn btn-lg btn-primary sf-btn btn-specials"
                  to="/specials">
                  {t('common:specialOffers')}
                </Link>
              </label>
            </div>
          </div>
      );
    }
    else {
      return null;
    }
  }

  return (
    <div className="Home">
      <ChangeLanguageButton {...props} />
      <Jumbotron headline={t('holiday in a caravan')} subtitle={t('experience portugal')}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xs-12 col-sm-8 col-md-5 searchform p-2">
              <SearchForm {...props} />
            </div>
          </div>
          
          <div className="row justify-content-center">
            <div className="pt-3"><Link className='reviews noHover' to='/reviews'>{t('what our customers say')}:</Link></div>
            <div className="py-3 pl-1 reviews-stars"><Link className="reviews-link noHover" to='/reviews'><ReviewStars stars={avgStars} />({numReviews})</Link></div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xs-12 col-sm-8 col-md-5 p-2">
              <div className="row">
                <div className="col-12 px-3 pt-2 pb-0">
                  <label className="sf-btn-label">
                    <Link
                      role="button"
                      className="btn btn-lg btn-primary sf-btn"
                      to="/vehicles">
                      {'> ' + t('our caravans') + ' <'}
                    </Link>
                  </label>
                </div>
              </div>
              <SpecialsButton specials={specials} />
            </div>
          </div>
        </div>
      </Jumbotron>
      <h2 className="p-3">{t('Rent a caravon today')}</h2>
      <div className="container">
        <div className="row justify-content-center">
          <Gallery {...props} />
        </div>
        <p className="lead">{t('text welcome')}</p>
        <p className="lead">{t('text shuttle service')}</p>
        <p className="lead">{t('text enjoy holidays')}</p>
        <p className="lead">{t('text want to rent a caravan')}</p>
        <h2 className="p-3">{t('you can find us here')}</h2>
        <div className="row py-3">
          <div className="col-md-6 pb-3">
            <b>minhaautocaravana</b><br />
            Estrada Nacional 109 número 232<br />
            3840-061 Calvão<br  />
            Portugal<br  />
            Phone: <a href="tel:+351961374649">+351 961 374 649</a>
            { 
              (i18n.language === 'pt') ?
              <React.Fragment>
                <br />
                <small>
                <i>Todas as chamadas para os números descritos nos contactos poderão estar sujeitos a custos de chamada.<br />
                Custos de chamada para rede móvel nacional.
                </i></small>
              </React.Fragment>
              :
              null
            }
          </div>
          <div className="col-md-6 pb-3">
            <a target='_blank' rel='noreferrer' href="https://g.page/minhaautocaravana?share">
              <img width="100%" height="auto" src={locationImage} alt="Google Maps screenshot of our location in Calvão" title="Google Maps screenshot of our location in Calvão" />
            </a>
          </div>
        </div>
        <div className="text-center py-3">
          <Link role="button" className="btn btn-lg btn-primary btn-block" to="/vehicles">{t('select and rent caravan')}</Link>
        </div>
      </div>
    </div>
  );
}

export default withTranslation('home', 'common')(Home);